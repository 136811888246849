import { SCHOOL_STATUS } from "constants/enums/school_status";
import { PropTypes } from "prop-types";
import SchoolCardLink from "./SchoolCardLink";
import pluralize from "pluralize";
import classNames from "classnames";

/**
 * Card component for interacting with schools on the School Selection page.
 */
export default function SchoolCard({
  school,
  lastCard,
  className,
  testID = "xSchoolCard",
  ...args
}) {
  const { name, city, state, status, uuid, num_open_seats } = school;

  const OpenSeats = () => {
    return (
      num_open_seats > 0 && (
        <p
          className="text-center text-ignite-grey-dark mt-1"
          data-testid="open-seats">
          {num_open_seats} open {pluralize("seats", num_open_seats)}
        </p>
      )
    );
  };

  const STATUS_UI = {
    [SCHOOL_STATUS.UNIMPORTED]: (
      <div className="w-[17rem] text-center">
        <p className="text-xl font-medium mb-1">
          Your SIS is not yet imported.
        </p>
        <p className="text-ignite-grey-dark">
          Reach out to your Customer Success Manager to begin this process.
        </p>
      </div>
    ),
    [SCHOOL_STATUS.IMPORTED_RECORDS]: (
      <div>
        <SchoolCardLink
          schoolId={uuid}
          url={`/schools/${uuid}/students-redirect?path=student-selection`}
          text="Start Student Setup"
        />
        <OpenSeats />
      </div>
    ),
    [SCHOOL_STATUS.NO_SEATS]: (
      <div className="w-[17rem] text-center">
        <p className="text-xl font-medium mb-1">
          Seat count not yet configured.
        </p>
        <p className="text-ignite-grey-dark">
          Please reach out to your Customer Success Manager.
        </p>
      </div>
    ),
    [SCHOOL_STATUS.SI_INCOMPLETE]: (
      <div>
        <SchoolCardLink
          schoolId={uuid}
          url={`/schools/${uuid}/students-redirect?path=student-info-completion`}
          text="Complete Student Info"
        />
        <OpenSeats />
      </div>
    ),
    [SCHOOL_STATUS.IMPORTED_NO_RECORDS]: (
      <div className="w-[17rem] text-center">
        <p className="text-xl font-medium mb-1">No student records imported.</p>
        <p className="text-ignite-grey-dark">
          Adjust the permissions in your SIS to allow export student records to
          Ignite Reading.
        </p>
      </div>
    ),
    [SCHOOL_STATUS.OPEN_SEATS]: (
      <div>
        <SchoolCardLink
          schoolId={uuid}
          url={`/schools/${uuid}/students-redirect?path=student-selection`}
          text="Add Students to Program"
        />
        <OpenSeats />
      </div>
    ),
    [SCHOOL_STATUS.READY]: (
      <SchoolCardLink
        schoolId={uuid}
        url={`/schools/${uuid}/students-redirect?path=student-info-review`}
        text="View Students"
      />
    ),
  };

  const statusUI = STATUS_UI[status] || STATUS_UI.UNIMPORTED;

  return (
    <>
      <li
        data-testid={testID}
        className={classNames(
          "w-full h-[150px] flex min-w-[600px] py-14 items-center",
          { "border-b-[1px] border-b-neutral-300": !lastCard },
          className
        )}
        {...args}>
        <div className="w-2/4">
          <h3 className="font-bold text-ignite-purple-dark text-2xl">{name}</h3>
          <p className="mt-1 text-base font-medium">{`${city}, ${state}`}</p>
        </div>
        <div className="w-2/4 flex flex-wrap content-center justify-end">
          {statusUI}
        </div>
      </li>
    </>
  );
}

SchoolCard.propTypes = {
  school: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string,
    uuid: PropTypes.string,
    num_open_seats: PropTypes.number,
  }).isRequired,
  lastCard: PropTypes.bool.isRequired,
  className: PropTypes.string,
  testID: PropTypes.string,
};
