import { redirect } from "react-router-dom";
import NoStudentRecordsRoute from "./NoStudentRecords";
import SisNotImportedRoute from "./SisNotImported";
import AuthService from "services/Auth";
import SchoolService from "services/School";
import LandingRoute from "routes/AuthRoute/LandingRoute";
import AuthLayout from "layouts/AuthLayout";
import RosterRoute from "routes/AuthRoute/RosterRoute";
import { assignStatus } from "utils/schools";
import StudentSetupRoute from "routes/AuthRoute/StudentSetup";
import SIIncompleteRoute from "./SIIncomplete";
import OpenSeatsRoute from "./OpenSeats";
import SICompleteRoute from "./SIComplete";
import NoSeatsRoute from "./NoSeats";

export default {
  element: <AuthLayout />,
  id: "auth",
  loader: async () => {
    const SPHINX_GATE_API_URL = import.meta.env.VITE_SPHINX_GATE_API_URL;
    await AuthService.auth();
    if (AuthService.isAuthenticated) {
      SchoolService.api.token = AuthService.api.token;
      const { email, first_name, last_name, roles } =
        await AuthService.userinfo();

      const { schools } = await SchoolService.schools();
      assignStatus(schools);

      return {
        profileData: {
          email: email,
          firstName: first_name,
          lastName: last_name,
          roles: roles,
        },
        schools,
      };
    }
    return redirect(`${SPHINX_GATE_API_URL}/login?source=schools`);
  },
  children: [
    NoStudentRecordsRoute,
    OpenSeatsRoute,
    LandingRoute,
    RosterRoute,
    SIIncompleteRoute,
    SisNotImportedRoute,
    StudentSetupRoute,
    SICompleteRoute,
    NoSeatsRoute,
  ],
};
