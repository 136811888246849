import { useContext } from "react";
import { DataGridContext } from "../DataGridProvider";

function DataGridSearch() {
  const { search, onSearch } = useContext(DataGridContext);

  return (
    <div className="relative">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg
          className="h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fillRule="evenodd"
            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <input
        type="search"
        name="search"
        className="pl-10 pr-3 py-3 block text-sm border-x-[.75px] border-t-[.75px] border-ignite-grey-border shadow-sm rounded-tl-[5px] rounded-tr-[5px] box-shadow box-border w-[369px] focus-visible:outline-ignite-purple focus-visible:ring-ignite-purple focus-visible:border-ignite-purple"
        placeholder="Student Name"
        value={search}
        onChange={e => onSearch(e.target.value)}
      />
    </div>
  );
}

export default DataGridSearch;
