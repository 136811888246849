import { redirect } from "react-router-dom";

const SPHINX_GATE_API_URL = import.meta.env.VITE_SPHINX_GATE_API_URL;

export default class Auth {
  constructor(ApiService) {
    this.api = ApiService;
    this.isAuthenticated = false;
  }

  async auth() {
    try {
      const { status, data } = await this.api.fetch({
        url: "auth",
        params: { source: "schools" },
      });
      if (status == 200 && data.access_token) {
        this.api.token = data.access_token;
        this.isAuthenticated = true;
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    this.api.token = null;
    this.isAuthenticated = false;
    return redirect(`${SPHINX_GATE_API_URL}/login?source=schools`);
  }

  async signout() {
    this.api.token = null;
    this.isAuthenticated = false;
    return redirect(`${SPHINX_GATE_API_URL}/signout?source=schools`);
  }

  async signup(firstName, lastName, password, token) {
    const { status } = await this.api.fetch({
      body: {
        first_name: firstName,
        last_name: lastName,
        password: password,
        token: token,
      },
      url: "users",
      method: "post",
    });
    if (status == 201) {
      return true;
    }
    return false;
  }

  async userinfo() {
    const { data } = await this.api.fetch({
      url: "private",
    });
    return data;
  }
}
