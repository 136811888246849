import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useStudentsStore = create(
  devtools(set => ({
    students: [],
    setStudents: newStudents =>
      set({ students: newStudents }, false, "setStudents"),
    updateStudents: newStudents =>
      set(
        state => (
          { students: [...state.students, ...newStudents] },
          false,
          "updateStudents"
        )
      ),
  }))
);
