import PropTypes from "prop-types";
import classnames from "classnames";
import DownArrowIcon from "assets/icons/arrow_down.svg";
import { useEffect, useState, useRef } from "react";
import { WithWrapperRef } from "../WithWrapper";
import { Popover, PopoverContent, PopoverTrigger } from "components/Popover";
import { twMerge } from "tailwind-merge";
import SelectedOptions from "../DataGridMultiSelect/SelectedOptions";
import Options from "./Options";

/**
 * Styled select for the DataGrid.
 *
 * @param {boolean} [error = false] - boolean to indicate error state
 * @param {boolean} [disabled = false] - boolean to indicate disabled state
 * @param {string} value - value to display in the select
 * @param {string} name - name of the select
 * @param {string} id - id of the select
 * @param {string} className - additional classes to add to the component
 * @param {array} options - array of objects with label and value for the options
 * @param {string} columnHeader - column header for the field
 * @param {boolean} shouldStartPink - boolean to indicate if the field should start pink
 * @param {func} onBlur - function to run on blur event
 */
const DataGridSelect = ({
  error = false,
  disabled = false,
  value = "",
  name = "",
  id = "",
  className,
  options,
  columnHeader,
  shouldStartPink,
  onBlur,
  label = "Select",
  ...args
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [contentWidth, setContentWidth] = useState(null);

  const triggerRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    if (open && triggerRef.current) {
      const width = triggerRef.current.getBoundingClientRect().width;
      setContentWidth(width);
    }
  }, [open]);

  const handleSelectOption = eventValue => {
    setInputValue(eventValue);
    setOpen(false);
    onBlur({
      target: {
        name,
        value: eventValue,
      },
    });
  };

  // Needed for sorting
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleOpen = bool => {
    setOpen(bool);
    if (!bool) triggerRef.current?.focus({ preventScroll: true });
  };

  return (
    <Popover
      onOpenChange={handleOpen}
      open={open}
      placement="bottom"
      offset={{ mainAxis: 0 }}
      useFlip={false}
      useShift={false}
      toggle>
      <PopoverTrigger>
        <WithWrapperRef
          shouldStartPink={shouldStartPink}
          value={inputValue}>
          <button
            className={twMerge(
              classnames(
                "group flex items-center whitespace-nowrap h-full w-full justify-center",
                "outline-2 outline-offset-[-1px] focus:rounded focus:outline focus:outline-ignite-pink",
                {
                  "shadow-inner rounded outline outline-ignite-pink": open,
                }
              )
            )}
            data-testid={name}
            aria-label={`Select ${columnHeader}`}
            // NOTE: we disable this error because floating-ui adds aria attributes FOR us
            // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
            role="combobox"
            aria-haspopup="listbox"
            aria-expanded={open}
            aria-controls={`${id}-select-content`}
            id={id}
            ref={triggerRef}
            disabled={disabled}
            type="button"
            {...args}>
            {inputValue ? (
              <SelectedOptions
                selectedValues={[inputValue]}
                options={options}
                name={name}
                error={error}
              />
            ) : (
              <>
                <label
                  htmlFor={id}
                  className={classnames({
                    "cursor-pointer": !disabled,
                    "text-error-red": error,
                  })}>
                  {label}
                </label>
                <input
                  aria-hidden={true}
                  className="invisible absolute bottom-0"
                  name={name}
                  value={""}
                  readOnly
                />
              </>
            )}
            {/* hidden input to handle invalid state */}
            <input
              aria-invalid={error}
              aria-hidden={true}
              tabIndex={-1}
              className="invisible absolute bottom-0"
            />
            <DownArrowIcon
              className={classnames(
                "absolute pointer-events-none top-[calc(50%-.75em)] right-4 rounded-full box-content",
                {
                  "cursor-pointer group-focus:bg-ignite-pink group-hover:bg-ignite-pink group-focus:fill-white group-hover:fill-white":
                    !disabled,
                  "-rotate-180": open,
                }
              )}
            />
          </button>
        </WithWrapperRef>
      </PopoverTrigger>
      <PopoverContent
        hideArrow
        hideCloseButton
        className="rounded-lg mt-2 border border-solid border-zinc-300">
        <div
          ref={contentRef}
          data-testid={`${id}-select-content`}
          className={twMerge(
            "flex flex-col max-h-[300px] overflow-hidden",
            className
          )}
          style={{ width: contentWidth ? `${contentWidth}px` : "auto" }}>
          <Options
            options={options}
            onChange={handleSelectOption}
            selectedValue={inputValue}
            name={name}
            ariaLabel={`Select ${columnHeader}`}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

DataGridSelect.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]).isRequired,
    })
  ).isRequired,
  columnHeader: PropTypes.string.isRequired,
  shouldStartPink: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
};

export default DataGridSelect;
