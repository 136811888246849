import {
  useParams,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useStudentsStore } from "store/students";

/**
 * Component which redirects the user to the appropriate flow page after loading the students into the students store.
 */
export default function StudentsRedirect() {
  const { students } = useRouteLoaderData("students");
  const setStudents = useStudentsStore(state => state.setStudents);

  setStudents(students);

  const { schoolId } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={`/schools/${schoolId}/${searchParams.get("path")}`}
      replace={true}
    />
  );
}
