import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

const Options = ({ onChange, options, selectedValue, ariaLabel }) => {
  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current
        .getElementsByTagName("li")[0]
        ?.focus({ preventScroll: true });
    }
  }, [options]);

  const handleOptionClick = e => {
    const eventValue = e.currentTarget.getAttribute("data-value");
    onChange(eventValue);
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      const eventValue = e.currentTarget.getAttribute("data-value");
      onChange(eventValue);
    }
  };

  return (
    <ul
      className="overflow-y-auto rounded-lg"
      ref={contentRef}
      role="listbox"
      aria-activedescendant={selectedValue || ""}
      aria-label={ariaLabel}
      tabIndex={-1}>
      {options.map(option => (
        <li
          className="w-full flex items-center justify-center hover:bg-zinc-100/70 py-1 border-solid border-zinc-300 border-b cursor-pointer"
          key={option.value}
          data-value={option.value}
          onClick={handleOptionClick}
          onKeyDown={handleKeyDown}
          role="option"
          aria-selected={selectedValue === option.value}
          tabIndex={0}
          data-testid={`select-option-${option.value}`}>
          <span className="py-3 px-3 text-md text-center">{option.label}</span>
        </li>
      ))}
    </ul>
  );
};

Options.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]).isRequired,
    })
  ).isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  onBlur: PropTypes.func,
  ariaLabel: PropTypes.string,
};

export default Options;
