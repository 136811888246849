import PropTypes from "prop-types";
import OrangeErrorIcon from "assets/icons/orange-error.svg";
import Button from "components/Button";

export default function ErrorItem({ error, disableLink }) {
  const { message, cellId } = error;

  const linkToCell = () => {
    const targetCell = document.getElementById(cellId);

    targetCell.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    targetCell.focus();
  };

  return (
    <div className="flex gap-3">
      <OrangeErrorIcon className="shrink-0 my-auto" />
      {cellId && !disableLink ? (
        <Button
          design="link"
          onClick={linkToCell}
          className="flex flex-col justify-center text-left">
          {message}
        </Button>
      ) : (
        <div className="flex flex-col justify-center text-left">{message}</div>
      )}
    </div>
  );
}

ErrorItem.propTypes = {
  error: PropTypes.shape({
    /** The error message */
    message: PropTypes.string.isRequired,
    /** The id of the input producing the input producing the error */
    cellId: PropTypes.string,
  }).isRequired,
  disableLink: PropTypes.bool,
};
